// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    name: 'Walden', 
    assetBaseUrl:'https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2F',
    authService: 'microsoft.com',
    tenantId:'7e53ec4a-d325-4228-9e0e-a55a6b8892d5',
    oktaAuthService:'oidc.okta',
    clientId:'0oa2342v06zO3TrHA0h8',
    issuer:'https://atge.oktapreview.com',
    //redirectUri:'http://localhost:4200/login/callback',
    redirectUri:'https://genai-dev.waldenu.edu/login/callback',
    oktaSignoutRedirectUri: 'https://genai-dev.waldenu.edu',
    studentAzureChatBotServiceFlag:true,
    studentGCPChatBotServiceFlag:true,
    studentAvaamoChatBotServiceFlag:true,
    chatbotName:'Charlotte',


    studentAsStaffConfig: {
      chatUrl: 'https://charlotte-microapp-dev.web.app/assets/js/chatbot.js',
      channelId: '9f5def8c-ab6f-4b46-9219-28902133aac5',
      botApiUrl: "https://bis-api-dot-app-studentportal-dev.ue.r.appspot.com/staffassistantdev/token",
      sasCookies : "https://genai-copilot-mmp-dev.azurewebsites.net/create_user_delegation_sas_container/",
      sasToken: "https://genai-copilot-mmp-dev.azurewebsites.net/create_user_delegation_sas_container/",
      refreshChat: "https://genai-copilot-mmp-dev.azurewebsites.net/clear_history_api/",
      searchApiRequest: "https://genai-copilot-mmp-dev.azurewebsites.net/search_api_request/",
      chatBotId: "",
      convoAction: "https://genai-copilot-mmp-dev.azurewebsites.net/convo_action/",
      convoFeedback: "https://genai-copilot-mmp-dev.azurewebsites.net/convo_feedback/",
      getChatbotId: "https://genai-copilot-mmp-dev.azurewebsites.net/get_chatbot_id/",
      citationPayloadLink: "https://genai-copilot-mmp-dev.azurewebsites.net/citation_category_group_api/",
      chatHistory : "https://genai-copilot-mmp-dev.azurewebsites.net/get_chat_history_api/",
    },
    enrollmentConfig: {
      sasCookies : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/create_user_delegation_sas_container/",
      refreshChat : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/clear_history_api/",
      searchApiRequest : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/search_api_request/",
      citationPDF : "https://genaicopilotmmpdevblob.blob.core.windows.net/",
      citationPayloadLink : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/citation_category_group_api/",
      convoActionAPI : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/convo_action/",
      convoFeedbackAPI: "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/convo_feedback/",
      chatHistory : "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/get_chat_history_api/",
			chatBotId: "",
      getChatbotId: "https://dev-apigateway.adtalem.com/wu-mmp-charlotte-chatbot-genai/1.0/get_chatbot_id/",
      getAuthToken: "https://genai-copilot-mmp-dev.azurewebsites.net/get_token/",
      salesforceConfigUrl: "https://laureateone--dev.sandbox.lightning.force.com"
    },

    // salesforceConfig: {
    //   url: 'https://laureateone--dev.sandbox.lightning.force.com',
    // },
      Question1:"What support services are available for Walden students?",
      Question2:"How is Walden's faculty different from other online universities?",
      Question3:"Can you tell me about Walden's accreditation?"
};


