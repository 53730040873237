declare function setApiUrlsForEnrollment(a: any): any;
declare function getChatbotIdForEnrollmentBot(): any;
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enrollment-agent',
  templateUrl: './enrollment-agent.component.html',
  styleUrls: ['./enrollment-agent.component.scss']
})
export class EnrollmentAgentComponent {
  public boxArea = "box1";
  public buttonArea = "btn-area";
  public showAcknowledge = false;
  public isDisabled = true;
  public disabledButton = false;
  public chatIconShow = false;
  public toShow = false;
  public assetsPath = AssetsPath;
  public toshowTick = false;
  public title = `Chat bots for ${environment.name} University`;
  public isVisible: boolean = false;
  public showDiv: boolean = true;
  acknowledged: boolean = false;

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth){}

  ngOnInit(){
    setApiUrlsForEnrollment(environment.enrollmentConfig);
    const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
    sessionStorage.setItem('access_token',oktaToken.accessToken);
    this.userEnrollmentData();
    this.checkAcknowledgment();
  }

  async userEnrollmentData(){
    const user = await this.oktaAuth.getUser();
    sessionStorage.setItem('userName',user.name)
    sessionStorage.setItem('userEmail',user.email);
    getChatbotIdForEnrollmentBot();
  }

  public clickAcknowledgment()
{
  this.isDisabled= !this.isDisabled;
}

public readAcknowledgment()
{
  this.showAcknowledge= !this.showAcknowledge;
}
public startConversation(){
  this.showAcknowledge= !this.showAcknowledge;
  this.boxArea = "box1 landing"
  this.buttonArea = "accepted_button";
  this.disabledButton = true;
  this.toshowTick = true;
  this.chatIconShow = true;
}
public closeButton()
{
  this.showAcknowledge = !this.showAcknowledge;
}

public toggleVisibility() {
  this.isVisible = !this.isVisible;
}

public FAQs = [
  {category:"Tuition & Fees",query:"What are the scholarship options available?"},
  {category:"Tuition & Fees",query:"What are the different modes are available?"},
  {category:"Tuition & Fees",query:"When will the scholarship be available?"},
  {category:"Tuition & Fees",query:"Am I eligible for scholarship?"},
  {category:"Tuition & Fees",query:"Tuition & Fees details for Tempo learning?"}
]

public showChatFAQs=[];

applyFAQsSelection(){
  var applyFAQ = document.getElementById("applyFaq");
  applyFAQ.style.backgroundColor = "#C4C4C4";
  var selectElement = document.getElementById("categorySelect") as HTMLSelectElement;
  var selectedOption = selectElement.options[selectElement.selectedIndex].value;
  console.log("Selected options : ",selectedOption);
  if(this.FAQs.length>0){
    this.showChatFAQs=[];
    this.FAQs.forEach((FAQ)=>{
      if(selectedOption===FAQ.category){
        this.showChatFAQs.push({'query':FAQ.query});
      }
    })
  }
  console.log("Response list: ",this.showChatFAQs);
}

maximizeChat() {
  const chatPopup = document.querySelector('.hk-chatbot-popup');
  if (chatPopup.classList.contains('maximized')) {
    chatPopup.classList.remove('maximized');
  } else {
    chatPopup.classList.add('maximized');
  }
}

fadeInFadeOut() {
  this.showDiv = !this.showDiv;
}

checkAcknowledgment() {
  const acknowledgedString = localStorage.getItem('termsAcknowledged');
  if (acknowledgedString === 'true') {
    this.acknowledged = true;
    this.buttonArea = "accepted_button";
    this.toshowTick = true;
  this.chatIconShow = true;
  }
}

acknowledgeTerms(checked: boolean) {
  this.acknowledged = checked;
  localStorage.setItem('termsAcknowledged', checked ? 'true' : 'false');
}

async logout(){
  //const logoutUrl = '/enrollmentcopilot';
  //sessionStorage.clear();
  //this.removeAllCookies();
  //await this.oktaAuth.signOut({postLogoutRedirectUri: `${environment.oktaSignoutRedirectUri}?returnUrl=${logoutUrl}`});
  sessionStorage.setItem('lastRoute','/enrollmentcopilot')
  await this.oktaAuth.signOut();
}

removeAllCookies(){
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/enrollmentcopilot`;
  });
}

}